import React, { useEffect } from 'react';

import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import {Divider} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import logo from './logo.png';
import topschool from './Org/topschool.png';
import DPA from './Org/DPA_School.png';
import titleimg from './design/titleimg.png';
 import prisms from './Org/prisms.png';
 import history from './history';
 import dividerimg from './design/divider.png';
  import axios from 'axios';
const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  formdiv: {
      position:'relative',
      marginLeft:10,
      // bottom:80,
      marginRight:10,
    //  width: '100%', // Fix IE 11 issue.
     marginTop: theme.spacing(1),
     [theme.breakpoints.up('md')]: {
         width:'28%',
         marginTop:'2%',
        //  marginLeft:80,
         marginBottom:'4%',
         top:'50%',
         left:'49%',
        //  transform: 'translateY(-50%)',
        transform: 'translate(-50%,-50%)',
         position: 'absolute',
         
     },
  },
  submit: {
    margin: theme.spacing(6, 0, 2),
    color:'#28305d',
     fontFamily:'system-ui',
     borderRadius:23,
     fontWeight:'bold',
     transform: 'translate(-50%, -50%)',
    left: '50%',
     width: '100%',
    background: '#28305d',
    color: 'white',
    '&:hover': {
      backgroundColor: '#355479'
    },
  },
  logo:{
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '50%',
    //   marginLeft:'27%',
    //   width:200,
    [theme.breakpoints.up('md')]: {
      marginLeft:155,
      width:200,
    //   height:'20%'
  },
},
  title:{
    marginLeft:8,
    fontWeight:'bold',
    fontSize:20,
    color:'#fb607f',
    textAlign:'center',
    marginTop:'1%',
    [theme.breakpoints.up('md')]: {
      // marginLeft:36,
      fontWeight:'bold',
      fontSize:35,
      color:'#fb607f',
      textAlign:'center',
      marginTop:'4%'
  },
},
  date:{
    fontSize:18,
    color:'#28305d',
    // marginLeft:165,
    fontWeight:'bold',
    textAlign:'center',
    marginTop:'2%',
    [theme.breakpoints.up('md')]: {
    fontSize:21,
    color:'#28305d',
    // marginLeft:165,
    fontWeight:'bold',
    textAlign:'center',
    marginTop:2,
  },
},
date1:{
  fontSize:21,
  color:'#28305d',
  // marginLeft:165,
  fontWeight:'bold',
   textAlign:'center',
  [theme.breakpoints.up('md')]: {
  fontSize:21,
  color:'#28305d',
   marginLeft:'10%',
  fontWeight:'bold',

   textAlign:'inherit',
},
},
  we:{
    fontSize:31,
    color:'#28305d',
    //  marginLeft:145,
     textAlign:'center',
    // marginTop:5,
    marginBottom:20,
    [theme.breakpoints.up('md')]: {
    fontSize:31,
    color:'#28305d',
    position:'fixed',
    top:'91%',
    left:'50%',
    transform:'translate(-50%, -50%)',
    marginTop:25,
    marginBottom:0,
    // marginLeft:'15%',
    // textAlign:'center',
    // marginTop:'2%',
    // marginBottom:'10%'
    },
    // fontWeight:'bold'
  },
  Orgbottom:{
    fontSize:19,
    color:'#28305d',
    //  marginLeft:145,
     textAlign:'center',
    marginTop:25,
    [theme.breakpoints.up('md')]: {
    fontSize:20,
    color:'#28305d',
    marginLeft:'2%',
    textAlign:'inherit',
    marginTop:'2%',
    // textAlign:'center',
    position: 'fixed',
    fontFamily:'system-ui',
    bottom: '12vh',
    left: '50%',
transform: 'translateX(-50%)',

    margiTop: 1,
    fontWeight: 'bold',
    },
    // fontWeight:'bold'
  },
  we1:{
    fontSize:31,
    color:'#fb607f',
    // marginLeft:145,
    textAlign:'center',
    marginTop:25,
    [theme.breakpoints.up('md')]: {
    fontSize:31,
    color:'#fb607f',
    marginLeft:10,
    textAlign:'inherit',
    marginTop:'2%',
    width:'78%'
      // textAlign:'center'
    },
    // fontWeight:'bold'
  },
  root:{
    // background: `url(${RImage})`,
  },
  img:{
      display:'none',
    [theme.breakpoints.up('md')]: {
        display:'flex',
      position:'fixed',
      right:0,
      top:0,
      height:'100%' ,
      width:'45%'
},
},
img1:{
    // display:'flex',
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '35%',
     
    [theme.breakpoints.up('md')]: {
     display:'none'
},
},
  cont:{
    position:'fixed',
    bottom:147,
    left:5,
    [theme.breakpoints.up('md')]: {
      position:'fixed',
      bottom:147,
      left:80,
    },
  },
btntxt:{
// color:'#28305d'
color: 'white',

},
notchedOutline: {
    // borderWidth: "1px",
    borderColor: "#28305d !important"
  },
  cre:{
    display: 'inline-block',
    // marginLeft: 'auto',
    // marginRight: 'auto',
    width: '15%',
    height:'4vh',
    marginLeft:5,
    [theme.breakpoints.up('md')]: {
      // marginLeft:155,
      width:'9%',
      height:'7vh',
      display: 'inline-block',
      marginLeft:'2%',
      objectFit:"contain",
      // position:'fixed',
      // bottom:5,
    //   height:'20%'
  },
},
mak:{
  display: 'inline-block',
  // marginLeft: 'auto',
  // marginRight: 'auto',
  height:'4vh',
  width: '15%',
  marginLeft:5,
  [theme.breakpoints.up('md')]: {
    // marginLeft:155,
    width:'9%',
    height:'8vh',
    display: 'inline-block',
    marginLeft:'1%',
    objectFit:"contain",
    // marginTop:20
  //   height:'20%'
},
},
orgdiv:{
  width:'100%',
  [theme.breakpoints.up('md')]: {
  width:'100%' ,
  display:'flex-root',
   textAlign:'center',
   position: 'fixed',
    bottom: 10,
  }, 
},
imgdiv:{

},


titleimg:{
  display:'flex',
  position:'relative',
  // right:0,
  top:0,
   height:'15%' ,
  width:'100%',
[theme.breakpoints.up('md')]: {
    display:'flex',
  position:'fixed',
  // right:0,
  top:0,
   height:'17%' ,
  width:'100%'
},
},
titlediv:{
  display:'block'
},

lived:{
  fontSize:25,
  color:'#fb607f',
  // marginLeft:145,
  textAlign:'center',
  marginTop:25,
  [theme.breakpoints.up('md')]: {
  fontSize:31,
  color:'#fb607f',
  // marginLeft:10,
  textAlign:'inherit',
  marginTop:'2%',
  width:'78%'
    // textAlign:'center'
  },
  // fontWeight:'bold'
},
livediv:{
  width:'100%',
  [theme.breakpoints.up('md')]: {
    position:'fixed',
    left:'70%',
    bottom:'15%',
  // width:'100%' ,
  // display:'flex-root',
  // textAlign:'center'
  },
},
lived1:{
  fontSize:16,
  color:'#28305d',
  textAlign:'center',
  // marginLeft:145,
  textAlign:'center',
  marginTop:25,
  [theme.breakpoints.up('md')]: {
  fontSize:17,
  color:'#28305d',
  marginLeft:10,
  textAlign:'inherit',
  marginTop:'2%',
  width:'47%'
    // textAlign:'center'
  },
  // fontWeight:'bold'
},
livediv1:{
  width:'100%',
  [theme.breakpoints.up('sm')]: {
    
    position:'fixed',
    left:'50%',
    bottom:'4%',
    textAlign:'center'
  //  width:'100%' ,
  // display:'flex-root',
  // textAlign:'center'
  },
},
divider2:{
  width:'100%',
  [theme.breakpoints.up('md')]: {
    position:'fixed',
    bottom:'10vh',
    width:'67%',
    left:'18%',
    height:17
  },
},
  
}));
function getFaviconEl() {
    return document.getElementById("favicon");
  }
export default function SignIn(props) {
  const classes = useStyles();

  const [name,setName]=React.useState('');
  const [mobile,setMob]=React.useState(null);
  const [userdata,setUserdata]=React.useState(null);
  useEffect(()=>{
 

const favicon = getFaviconEl(); // Accessing favicon element
    favicon.href = logo;
  },[])
  const JoinE=()=>{
    // history.push({pathname:"/live"});
    // var phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    var phoneno = /^[+]{0,1}[0-9]{10,20}$/
    if(name.length>0 && mobile!=null && phoneno.test(mobile)){
      console.log(mobile);
      axios.post('https://topschooldev.prisms.in/rest/index.php/user_list.json?',{['fun_name']:"RamanScienceFest",['sid']:258,['name']:name,['mobile_no']:mobile}).
      then (res=> {
        console.log(res.data);
        localStorage.setItem('data',JSON.stringify(res.data))
        setUserdata(res.data)
          if(res.data!=null){
          history.push({pathname:"/live",data:res.data});
          }
          else{
            window.alert("Please Try Again");
          }
        })
      }
    else{
      window.alert("Please Enter The details Correctly")
    }
  }
  return (<div className={classes.root}>
    
      <div className={classes.titlediv}>
          
           <img src={titleimg} className={classes.titleimg}></img>
        
           <Typography className={classes.title}>DPA SCHOOL GATHERING </Typography>
           <Typography className={classes.date}>28TH FEB 2021, SUNDAY</Typography>
           <Typography className={classes.date}>9:00 AM TO 2:00 PM</Typography>
       </div>
   
       <div>
       
       </div>

        <div className={classes.formdiv}>
        {/* <div className={classes.livediv}>
          <Typography className={classes.lived} style={{marginTop:1, fontWeight:'bold'}}>LIVE NOW</Typography>
          </div> */}
        <p  style={{ fontFamily:'system-ui',textAlign:'center',marginTop:20, }}>Confirm your registration</p>
          <TextField style={{marginBottom:20}}
            variant="outlined"
            margin="Name"
            required
            fullWidth
            id="email"
            label="Name"
            name="Name"
            value={name}
            InputProps={{
                classes: {
                  notchedOutline: classes.notchedOutline
                }
              }}
             onChange={(e)=>{setName(e.target.value)}}
            autoComplete="Name"
            autoFocus
          />
          <TextField
            variant="outlined"
            margin="Mobile No"
            required
            fullWidth
            name="Mobile No"
            label="Mobile No"
            type="Mobile No"
            id="Mobile No"
            value={mobile}
            InputProps={{
                classes: {
                  notchedOutline: classes.notchedOutline
                }
              }}
              style={{marginBottom:13}}
             onChange={(e)=>{setMob(e.target.value)}}
            autoComplete="Mobile No"
          />
          {/* <a onClick={()=>window.open('/material', '_blank')} style={{marginTop:20, fontStyle:'italic',textAlign:'center',textDecoration:'underline',color:"#31ababc9",cursor:'pointer'}}><p style={{textAlign:'center',margin:0}}> Material List for STEM Workshops - Click Here</p></a> */}
          <Button
          classes={{ label: classes.btntxt }}
           onClick={()=>JoinE()}
            // fullWidth
            variant="outlined"
            // variant="contained"
              color="inherit"
           
            className={classes.submit}
          >
            Join LIVE Event
          </Button>
          {/* <img src={dividerimg} className={classes.divider3} /> */}
          {/* <img src={dividerimg} className={classes.divider4} /> */}
          {/* <Typography className={classes.we} style={{ fontFamily:'system-ui',fontWeight:'bold',}}>LIVE NOW</Typography> */}
          
          </div>
          
          {/* <div className={classes.livediv1}>
          <Typography className={classes.lived1} style={{marginTop:1, fontWeight:'bold'}}>
            Fun Chemistry Experiments .. Robotics .. STEM Projects..
            Science Experience Zone, Talk from Scientist
            Quiz.. Games and much more</Typography>
          </div> */}
          <div>
            <Typography className={classes.Orgbottom} style={{marginTop:1,fontWeight:'bold'}}>ORGANIZERS  AND KNOWLEDGE PARTNERS</Typography>
          </div>
         <div >
           <img src={dividerimg} className={classes.divider2} />
         </div>
          <div className={classes.orgdiv} style={{display:'inline-block'}}>
            <img src={DPA} className={classes.mak} />   
            <img src={topschool} className={classes.mak} />
      
            <img src={prisms} className={classes.mak}/>
          </div>
        {/* </form> */}
  
    </div>
  );
}