import React,{useEffect,useState,useRef} from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
//import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import withWidth,{isWidthUp} from '@material-ui/core/withWidth';
import Sidelist from './Sidelist';
import Sidelistlarge from './Sidelistlarge';
// import Videos from './Videos';
import divider from './divider.png';
import logo from './logo.png';
import {Button} from '@material-ui/core';

import history from './history';

const drawerWidth = 350;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    // backgroundImage: `url(https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT7fmKfue1LoKg9CmH_a2gE9SoIt4HcAoZ4rQ&usqp=CAU)`,
  },
  '@global': {
    '*::-webkit-scrollbar': {
      width: '0.4em'
    },
    '*::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
    },
    '*::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.1)',
      outline: '1px solid slategrey'
    }
  },
  drawer: {
      width:'100%',
    [theme.breakpoints.up('md')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
      width:'100%',
    [theme.breakpoints.up('md')]: {
    width: drawerWidth,
    },
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  divider:{
    width:'94%',
    marginTop:5
  },
  title:{
      textAlign:'center',
      fontSize:20,
      marginBottom:12
  },
  select:{
   position:'absolute',
   top:20,
},
live:{
      
//  display:'none',
  [theme.breakpoints.up('sm')]: {
     //position:'fixed',
    //   // display:'absolute',
      // top:'10%',
      // right:0,
      minWidth: '10%',
  maxWidth: 200,
  minHeight: '10%',
  maxHeight: 150,
      // width:460,
      //  height:249,
        // margin: '0 auto',

  },
},
titletxt:{
  fontSize:31,
  color:'#000000',
  // marginLeft:145,
  textAlign:'center',
  marginTop:25,
  [theme.breakpoints.up('sm')]: {
  fontSize:20,
  color:'#000000',
  //  marginLeft:'35%',
  textAlign:'center',
  marginTop:'2%',
  width:'100%',
  marginBottom:12,
    // textAlign:'center'
  },
  // fontWeight:'bold'
},
submit: {
    margin: theme.spacing(3, 0, 2),
    color:'#28305d',
     fontFamily:'system-ui',
     fontWeight:'bold',
     marginLeft:20,
  },
  labelbtn:{
    display:'block'
  }
}));
const Data = [
        {
        url: [{
          group:'All',
          link:'https://www.youtube.com/embed/i6tX2KMGUj0',
          subtitle:'Inauguration'
        },
      ],
        title: 'Inauguration',
        details:'Inauguration',
        tstart:' 9:00 AM ',
        tend:' 9:30 AM '

       },
   
       {
        url:[{
          group:'6 to 12 Years',
          link:'https://www.youtube.com/embed/xIj1ZyJFq1Q?rel=0',
          subtitle:'Think like a Scientist - Science Show by  ScienceUtsav '
        },
        {
          group:'12 and Above',
          link:'https://www.youtube.com/embed/Cz2Ca8N-KPc?rel=0',
          subtitle:'Understand Light with Eminent Scientist Professor Rajaram Nityananda'
        },
      ],
        title: 'Science Show - Science Talks',
        details:'Science Show - Science Talks',
        tstart:' 9:30 AM ',
        tend: ' 10:20 AM ',
       },
       {
        url: [{
          group:'All',
          link:'https://www.youtube.com/embed/i6tX2KMGUj0',
          subtitle:'Cultural Event - Instrument Play'
        },
      ],
        title: 'Cultural Event - Instrument Play',
        details:'Cultural Event - Instrument Play',
        tstart:' 10:20 AM ',
        tend:' 11:30 AM '

       },
       {
        url: [{
          group:'All',
          // link:'https://www.youtube.com/embed/OCm-hWN1G-Q',
          subtitle:'Science Games'
        },
      ],
        title: 'Science Games',
        details:'Science Games',
        tstart:' 11:30 AM ',
        tend:' 11:40 AM ' 
       },
       {
        url: [{
          group:'6 to 8 Years',
          // link:'https://www.youtube.com/embed/OCm-hWN1G-Q',
          subtitle:'Kiddo Science Quiz'
        },
        {
          group:'8 and 12 Years',
          // link:'https://www.youtube.com/embed/OCm-hWN1G-Q',
          subtitle:'Junior Science Quiz'
        },
        {
          group:'12 Years Above',
          // link:'https://www.youtube.com/embed/OCm-hWN1G-Q',
          subtitle:'Science Quiz'
        },
      ],
        title: 'Science Quiz',
        details:'Science Quiz',
        tstart:' 11:40 AM',
        tend:' 12:00 PM'
       },
       {
        url: [{
          group:'All',
          // link:'https://www.youtube.com/embed/OCm-hWN1G-Q',
          subtitle:'Cultural Event - Singing'
        },
      ],
        title: 'Cultural Event - Singing',
        details:'Cultural Event - Singing',
        tstart:' 12:00 AM ',
        tend:' 12:30 AM ' 
       },
       {
        url: [{
          group:'All',
          // link:'https://www.youtube.com/embed/OCm-hWN1G-Q',
          subtitle:'Science Games'
        },
      ],
        title: 'Science Games',
        details:'Science Games',
        tstart:' 12:30 PM ',
        tend:' 13:00 PM '
       },
       {
        url: [{
          group:'All',
          // link:'https://www.youtube.com/embed/OCm-hWN1G-Q',
          subtitle:'Cultural Event - Dance'
        },
      ],
        title: 'Cultural Event - Dance',
        details:'Cultural Event - Dance',
        tstart:' 13:00 PM ',
        tend:' 13:30 PM '
       },
       {
        url: [{
          group:'All',
          // link:'https://www.youtube.com/embed/OCm-hWN1G-Q',
          subtitle:'Prize distribution and Vote of Thanks'
        },
      ],
        title: 'Prize distribution and Vote of Thanks',
        details:'Prize distribution and Vote of Thanks',
        tstart:' 13:30 PM ',
        tend:' 14:00 PM ' 
       },
        
    ];
    function getFaviconEl() {
        return document.getElementById("favicon");
      }
//drag


function Fhome(props) {
  const { windows } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [Video,setVideo]=React.useState(null);
  const [AgeGroup, setAgegroup] = React.useState(null);
  const [currtime, setTime] = React.useState(null);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const handleVideo = (v) => {
    setVideo(v)
  };
  useEffect(()=>{

        const favicon = getFaviconEl(); // Accessing favicon element
        favicon.href = logo;
        if(Video==null){
            setVideo(Data[0])
            
        }
     
            
      },[])
  const drawer = (
    <div>
      {/* <div className={classes.toolbar} /> */}
      {/* <Divider /> */}
     {isWidthUp('md', props.width)?<Sidelistlarge data={Data} videoset={handleVideo}/>: <Sidelist data={Data}  videoset={handleVideo}/>}
    </div>
  );

  const container = windows!== undefined ? () => windows().document.body : undefined;
  const handleChangeAge = (event) => {
    console.log(event.target.value)
    setAgegroup(event.target.value);
    
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <nav className={classes.drawer} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden mdUp implementation="css">
          <Drawer
            container={container}
            variant="permanent"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={true}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        {/* <div className={classes.toolbar} /> */}
        {isWidthUp('md', props.width) && Video!=null?<>
        <div>
        <Typography className={classes.title}>{Video.details}</Typography>
        {Video.title=='AstroRanger Workshop'?<Typography className={classes.title}>23-Feb-2021</Typography>:null}
        <Typography className={classes.title}>{Video.tstart +"-"+Video.tend}</Typography>
        
        </div>
        <div>
            <img src={divider} className={classes.divider}/>
        </div>
      <p style={{textAlign:'center'}}>
        <Typography className={classes.titletxt}>Live Event</Typography>
        
        <iframe src='https://www.youtube.com/embed/3ypIehEbhGA?rel=0'
         allowFullScreen="allowfullscreen"
         mozallowFullScreen="mozallowfullscreen" 
         msallowFullScreen="msallowfullscreen" 
         oallowFullScreen="oallowfullscreen" 
         webkitallowFullScreen="webkitallowfullscreen"
        //  className={classes.live}
        style={{width:'50vw', height:'65vh'}}
        frameborder='1'
        allow='autoplay; encrypted-media'
        allowFullScreen
        title='video'
/>
</p>
<>

        </>
        </>:null}
       
        
      </main>
    </div>
  );
}


export default withWidth()(Fhome);
