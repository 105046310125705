import React, { Component } from "react";
import { Router, Switch, Route } from "react-router-dom";
import Join from './component/Join';
import Fhome from './component/Fhome';
 import history from './component/history';
 import PropTypes from 'prop-types';
//import Login from './Components/Login';
export default class Routes extends Component {
    render() {
        return (
            <Router history={history}>
                  {/* {  console.log = console.warn = console.error = () => {}} */}
                <Switch>                    
                    {/* <Route path="/:sid/:uid"  component={QuizForum} /> */}
                    <Route exact path="/"  component={Join} />
                    <Route exact path="/live"  component={Fhome} />
                   
                    {/* <Route path="/ProductDetail" exact component={Product_Details}/> */}
                </Switch>
            </Router>
        )
    }
}   