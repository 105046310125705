import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {List} from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    
  },
  logo:{
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '50%',
    //   marginLeft:'27%',
    //   width:200,
    [theme.breakpoints.up('sm')]: {
      // marginLeft:155,
      width:'20%',
    //   height:'20%'
  },
},
  backeven:{
      backgroundColor:'#597c94',
      color:'white',
      '&:hover': {
        backgroundColor: '#355479'
      },
      '&.Mui-selected': {
        backgroundColor: "#355479",
        color: "white",
        fontWeight: 600,
        '&:hover': {
          background: "#355479",
       },
    },
  },
  backodd:{
    backgroundColor:'#81aebd',
    color:'white',
    '&:hover': {
        backgroundColor: '#355479'
      },
      '&.Mui-selected': {
        backgroundColor: "#355479",
        color: "white",
        fontWeight: 600,
        '&:hover': {
          background: "#355479",
       },
    },
},
hov:{
  paddingTop:0,
},

}));

// function ListItemLink(props) {
//   return <ListItem button component="a" {...props} />;
// }

export default function Sidelistlg(props) {
  const classes = useStyles();
  const [selectedIndex, setSelectedIndex] = React.useState(1);
 

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };
  
  return (
    <div className={classes.root}>
      <List component="nav" aria-label="main mailbox folders" className={classes.hov}>
      
     {props.data.map((v,i)=>(<><ListItem selected={selectedIndex === i} button onClick={(event)=>{props.videoset(v);handleListItemClick(event, i)}} className={i%2==0?classes.backeven:classes.backodd}>
              
          <ListItemText primary={v.title} secondary={v.tstart +"-"+v.tend} />
          {/* {checklive(v)} */}
        </ListItem>
        <Divider/>
        </>))}
      </List>
    </div>
  );
}
