import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import {Typography} from '@material-ui/core';
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    color:'white',
     fontFamily:'system-ui',
     fontWeight:'bold',
    //  marginLeft:20,
    width:'80%',
  },
  heading: {
    fontSize:23,
    color:'white',
    flex:'auto',
    [theme.breakpoints.down('sm')]: {
      fontSize:18
    },
    // fontSize: theme.typography.pxToRem(15),
    // fontWeight: theme.typography.fontWeightRegular,
  },
  heading1: {
    fontSize:18,
    color:'white',
    display:'block',
    flex:'auto'
  },
 
  backeven:{
    backgroundColor:'#597c94',
    color:'white',
    '&:hover': {
      backgroundColor: '#355479'
    },
    '&.Mui-selected': {
      backgroundColor: "#355479",
      color: "white",
      fontWeight: 600,
      '&:hover': {
        background: "#355479",
     },
  },
},
backodd:{
  backgroundColor:'#81aebd',
  color:'white',
  '&:hover': {
      backgroundColor: '#355479'
    },
    '&.Mui-selected': {
      backgroundColor: "#355479",
      color: "white",
      fontWeight: 600,
      '&:hover': {
        background: "#355479",
     },
  },
},
live:{
  position:'fixed',
  bottom:15,
  width:240,
  height:190,
  right:'40%',
  [theme.breakpoints.down('sm')]: {
    width:240,
  height:190,
  right:'10%',
  left:'15%',
  },  
},
details:{
  [theme.breakpoints.down('sm')]: {
    padding:0
   },
},
   last:{
  [theme.breakpoints.down('sm')]: {
    padding:0,
    marginBottom:'30%'
   },
},
submit1: {
  margin: theme.spacing(3, 0, 2),
  color:'#28305d',
   fontFamily:'system-ui',
   fontWeight:'bold',
   marginLeft:20,
},
labelbtn:{
  display:'block'
},
qimg:{
maxWidth: '95%',
}
}));

export default function SideList(props) {
  const classes = useStyles();
  const [Video,setVideo]=React.useState(null);
  const [expanded, setExpanded] = React.useState(true);
  const handleVideo = (v) => {
    setVideo(v)
  };
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const len= props.data.length;

  return (
    <div className={classes.root}>
    
       {props.data.map((v,i)=>(<Accordion className={i%2==0?classes.backeven:classes.backodd} expanded={true} onChange={handleChange(i)}>
        <AccordionSummary
          // expandIcon={<IconButton onClick={()=>handleVideo(v)}> <ExpandMoreIcon /></IconButton>}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>{v.title}</Typography>
          <Typography className={classes.heading1}>{v.tstart +"-"+v.tend}</Typography>
        </AccordionSummary>
        
      </Accordion>))}
      <p style={{textAlign:'center'}}>
      <iframe src='https://www.youtube.com/embed/3ypIehEbhGA?rel=0'
       allowFullScreen="allowfullscreen"
       mozallowFullScreen="mozallowfullscreen" 
       msallowFullScreen="msallowfullscreen" 
       oallowFullScreen="oallowfullscreen" 
       webkitallowFullScreen="webkitallowfullscreen"
        className={classes.live}
        frameborder='1'
        // /allow='autoplay; encrypted-media'
        // allowFullScreen
        title='video'
/>
</p>
    </div>
  );
}
